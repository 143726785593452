import { toInteger, toString, get } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { IVehicleBrand } from "../types";

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "name",
    field: "name",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "description",
    field: "description",
    transform: { response: (value: any) => toString(value) },
  },
];

export const parseData = (json: any): IVehicleBrand => {
  return FIELD_MAPPING.reduce((acc: IVehicleBrand, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IVehicleBrand);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key })
);

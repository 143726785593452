import { toInteger, toString, get } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { IShop } from "../types";

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "name",
    field: "name",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "description",
    field: "description",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "address",
    field: "address",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "countryId",
    field: "country_id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "provinceId",
    field: "province_id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "cityId",
    field: "city_id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "barangayId",
    field: "barangay_id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "zipcode",
    field: "zipcode",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "isMainShop",
    field: "is_main_shop",
    transform: { response: (value: any) => Boolean(value) },
  },
  {
    key: "shopParentId",
    field: "shop_parent_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
];

export const parseData = (json: any): IShop => {
  return FIELD_MAPPING.reduce((acc: IShop, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IShop);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key })
);

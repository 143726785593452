import { toInteger, toString, get } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { IVehicle } from "../types";

export const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "customer",
    field: { response: "customer", payload: "customer_id" },
    render: (value: any) => toString(value?.name)
  },
  {
    key: "vehicleType",
    field: { response: "vehicle_type", payload: "vehicle_type_id" },
    render: (value: any) => toString(value?.name)
  },
  {
    key: "vehicleBrand",
    field: { response: "vehicle_brand", payload: "vehicle_brand_id" },
    render: (value: any) => toString(value?.name)
  },
  {
    key: "fuelType",
    field: { response: "fuel_type", payload: "fuel_type_id" },
    render: (value: any) => toString(value?.name)
  },
  {
    key: "plateNumber",
    field: "plate_no",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "model",
    field: "model",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "variant",
    field: "variant",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "color",
    field: "color",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "vehicleYear",
    field: "vehicle_year",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "vehicleFor",
    field: "vehicle_for",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "mileage",
    field: "current_mileage",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "chassisCode",
    field: "chassis_code",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "engineCode",
    field: "engine_code",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "registrationCertificate",
    field: "files[0]",
    transform: { response: (value: File) => value },
    hidden: true,
  },
  {
    key: "insurancePolicy",
    field: "files[1]",
    transform: { response: (value: File) => value },
    hidden: true,
  },
  {
    key: "officialReceipt",
    field: "files[2]",
    transform: { response: (value: File) => value },
    hidden: true,
  },
];

export const parseData = (json: any): IVehicle => {
  return FIELD_MAPPING.reduce((acc: IVehicle, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IVehicle);
};

export const parsePayload = (json: any): IVehicle => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {} as IVehicle);
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key, render: field.render })
);

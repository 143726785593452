import { combineReducers } from 'redux';

import listSlice from './list';
import formSlice from './form';
import typeSlice from "./type";
import brandSlice from "./brand";
import fuelTypeSlice from "./fuel-type";

export default combineReducers({
  list: listSlice,
  form: formSlice,
  type: typeSlice,
  brand: brandSlice,
  fuelType: fuelTypeSlice,
});

import React from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Button, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropdownMenu from "./DropdownMenu";
import {
  LogoutOutlined,
  ManageAccountsOutlined,
  Person2Outlined,
} from "@mui/icons-material";
import { useAuth } from "./AuthProvider";
import { navigate } from "gatsby";

interface ProfileMenuProps {
  compact?: boolean;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ compact = false }) => {
  const { user, logout, profilePicture } = useAuth();
  const [fullName, setFullName] = React.useState<string>("");
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    handleCloseUserMenu();
    try {
      await logout();
      navigate("/login");
    } catch (error) {}
  };

  React.useEffect(() => {
    if (user) setFullName(`${user.firstName} ${user.lastName}`);
  }, [user]);

  return (
    <React.Fragment>
      <Button
        sx={{
          px: 2,
          py: 1.5,
          my: 2,
          ...(!compact && {
            backgroundColor: "secondary.main",
            "&:hover": {
              backgroundColor: "secondary.dark",
            },
            borderRadius: 3,
            m: 2,
          }),
        }}
        variant={!compact ? "contained" : "light"}
        onClick={handleOpenUserMenu}
      >
        <Box display="inline-flex" flex={1}>
          <Avatar
            alt={fullName}
            {...(profilePicture?.id && { src: profilePicture.documentUrl })}
          />
          {!compact && (
            <Box textAlign="left" display="flex" flexDirection="column" mx={2}>
              <Typography
                textTransform="initial"
                sx={{ fontWeight: "bold" }}
                color="white"
                variant="body1"
              >
                {fullName}
              </Typography>
              <Typography textTransform="initial" variant="small" color="white">
                {user?.username}
              </Typography>
            </Box>
          )}
        </Box>
        {!compact && <KeyboardArrowDownIcon fontSize="large" />}
      </Button>
      <DropdownMenu
        id="menu-user"
        slotProps={{ paper: { sx: { minWidth: 180 } } }}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleCloseUserMenu}>
          <Person2Outlined />
          <Typography textAlign="center">Profile</Typography>
        </MenuItem>
        <MenuItem onClick={handleCloseUserMenu}>
          <ManageAccountsOutlined />
          <Typography textAlign="center">Account</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutOutlined />
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </DropdownMenu>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  compact: PropTypes.bool,
};

export default ProfileMenu;


import React, { useState } from 'react';
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Badge, Box, Breadcrumbs, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { useLocation } from '@reach/router';
import dayjs from 'dayjs';
import { Link } from 'gatsby';
import { startCase, upperCase } from 'lodash/fp';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  width: number;
}

const Appbar = styled(MuiAppBar, { shouldForwardProp: (prop) => !['open', 'width'].includes(prop as string) })<AppBarProps>(
  ({ theme, open, width }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: width,
      width: `calc(100% - ${width}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

Appbar.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.number.isRequired
}

interface NavbarProps {
  handleMenuToggle: () => void;
  AppBarProps?: AppBarProps;
}

const Navbar: React.FC<NavbarProps> = ({ handleMenuToggle, AppBarProps = {} as AppBarProps }) => {
  const location = useLocation();
  const [pathnames, setPathnames] = useState<string[]>([]);

  React.useEffect(() => {
    setPathnames(location.pathname.split('/').filter(x => x));
  }, [location]);
  return (
    <Appbar {...AppBarProps}>
      <Toolbar>
        <IconButton
          color="default"
          aria-label="menu toggle"
          onClick={handleMenuToggle}
          edge="start"
          sx={{ marginRight: !open ? 5 : 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Breadcrumbs aria-label="breadcrumb">
          {
            pathnames.map((pathname, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;

              const getPathname = (name: string) => {
                const allCapsList = ['soa'];

                if (allCapsList.includes(name)) return upperCase(name);

                return startCase(name);
              };

              return last ?
                <Typography key={pathname} color="text.primary">{getPathname(pathname)}</Typography> :
                <MuiLink underline="hover" color="inherit" key={pathname} component={Link} to={to}>{getPathname(pathname)}</MuiLink>
            })}
        </Breadcrumbs>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems='center'>
          <IconButton size="large" color="inherit">
            <HistoryOutlinedIcon />
          </IconButton>
          <IconButton size="large" color="inherit">
            <Badge badgeContent={10} color="error">
              <NotificationsNoneOutlinedIcon />
            </Badge>
          </IconButton>
          <Typography variant="body2" noWrap component="div" sx={{ ml: 2 }}>{dayjs().format('MMMM DD, YYYY')}</Typography>
        </Box>
      </Toolbar>
      <Divider />
    </Appbar>
  )
}

Navbar.propTypes = {
  handleMenuToggle: PropTypes.func.isRequired
};

export default Navbar;
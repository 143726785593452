import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import request, { isAxiosError } from "../../utils/axios";
import { IError } from "../../types/error";
import { IJobOrder } from "../../types";
import { ResponseStatus } from "../../constants";
import { parseData, parsePayload } from "../../transformers/job-order";

export const view = createAsyncThunk<
  IJobOrder,
  number,
  { rejectValue: IError }
>("job-order/form/view", async (id, { rejectWithValue }) => {
  try {
    const { data } = await request.get(`api/job-orders/${id}`);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const create = createAsyncThunk<
  IJobOrder,
  IJobOrder,
  { rejectValue: IError }
>("job-order/form/create", async (args, { rejectWithValue }) => {
  try {
    const payload = parsePayload(args);
    const { data } = await request.post("api/job-orders", payload);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const update = createAsyncThunk<
  IJobOrder,
  IJobOrder,
  { rejectValue: IError }
>("job-order/form/update", async (args, { rejectWithValue }) => {
  try {
    const payload = parsePayload(args);
    const { data } = await request.put(`api/job-orders/update`, payload);

    if (data.message || data.errors)
      throw data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const updateSchedule = createAsyncThunk<
  any,
  any,
  { rejectValue: IError }
>("job-order/form/updateSchedule", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.post("api/schedule-statuses", args);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return data.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const updateStatus = createAsyncThunk<any, any, { rejectValue: IError }>(
  "job-order/form/updateStatus",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await request.post("api/schedule-statuses", args);

      if (data.data.status === ResponseStatus.ERROR || data.data.errors)
        throw data.data;

      return data.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as { response: { data: any; status: any } };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

type InitialState = {
  data: IJobOrder | null;
  laborCosts?: number | null;
  partsCosts?: number | null;
  discounts?: number | null;
  total?: number | null;
  status: "loading" | "idle" | "created" | "updated" | "status-updated" | "jo-created";
  error: string | null;
};

const initialState: InitialState = {
  data: null,
  laborCosts: 0,
  partsCosts: 0,
  discounts: 0,
  total: 0,
  status: "idle",
  error: null,
};

export const slice = createSlice({
  name: "job-order/form",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(view.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.data = payload;
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(create.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(create.fulfilled, (state, { payload }) => {
      state.status = "created";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(update.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state, { payload }) => {
      state.status = "updated";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(updateStatus.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
      state.status = "status-updated";
      state.error = null;
    });
    builder.addCase(updateStatus.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(updateSchedule.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(updateSchedule.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(updateSchedule.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } =
  slice.actions;

export default slice.reducer;

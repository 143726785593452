import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import request, { isAxiosError } from "../../utils/axios";
import { IError } from "../../types/error";
import { IEstimate } from "../../types";
import { ResponseStatus } from "../../constants";
import { parseData, parsePayload } from "../../transformers/estimate";
import { startAppListening } from "../listenerMiddleware";
import { number } from "../../utils/functions";
import EstimateStatus from "../../constants/estimate-status";

export const view = createAsyncThunk<
  IEstimate,
  number,
  { rejectValue: IError }
>("estimate/form/view", async (id, { rejectWithValue }) => {
  try {
    const { data } = await request.get(`api/estimates/${id}`);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const create = createAsyncThunk<
  IEstimate,
  IEstimate,
  { rejectValue: IError }
>("estimate/form/create", async (args, { rejectWithValue }) => {
  try {
    const payload = parsePayload(args);
    const { data } = await request.post("api/estimates", payload);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const update = createAsyncThunk<
  IEstimate,
  IEstimate,
  { rejectValue: IError }
>("estimate/form/update", async (args, { rejectWithValue }) => {
  try {
    const payload = parsePayload(args);
    const { data } = await request.put(`api/estimates/${payload.id}`, payload);

    if (data.message || data.errors) throw data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const approve = createAsyncThunk<
  IEstimate,
  IEstimate,
  { rejectValue: IError }
>("estimate/form/approve", async (args, { rejectWithValue }) => {
  try {
    const payload = parsePayload(args);
    const { data } = await request.put("api/estimates/update", payload);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const updateSchedule = createAsyncThunk<
  any,
  any,
  { rejectValue: IError }
>("estimate/form/updateSchedule", async (args, { rejectWithValue }) => {
  try {
    const { data } = await request.post("api/schedule-statuses", args);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return data.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error as { response: { data: any; status: any } };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const updateStatus = createAsyncThunk<any, any, { rejectValue: IError }>(
  "estimate/form/updateStatus",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await request.post("api/estimate-statuses", args);

      if (data.data.status === ResponseStatus.ERROR || data.data.errors)
        throw data.data;

      return data.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as { response: { data: any; status: any } };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

type InitialState = {
  data: IEstimate | null;
  totalLaborCost: number;
  totalPartCost: number;
  totalToolCost: number;
  totalDiscount: number;
  total: number;
  status:
    | "loading"
    | "idle"
    | "created"
    | "updated"
    | "status-updated"
    | "jo-created";
  error: string | null;
};

const initialState: InitialState = {
  data: null,
  totalLaborCost: 0,
  totalPartCost: 0,
  totalToolCost: 0,
  totalDiscount: 0,
  total: 0,
  status: "idle",
  error: null,
};

export const slice = createSlice({
  name: "estimate/form",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
    setData: (state, action: PayloadAction<IEstimate>) => {
      state.data = action.payload;
    },
    setStatus: (state, action: PayloadAction<EstimateStatus | null>) => {
      state.data = { ...state.data, status: action.payload } as IEstimate;
    },
    setLaborCost: (state, action: PayloadAction<number>) => {
      state.totalLaborCost = action.payload;
    },
    setPartsCost: (state, action: PayloadAction<number>) => {
      state.totalPartCost = action.payload;
    },
    setToolsCost: (state, action: PayloadAction<number>) => {
      state.totalToolCost = action.payload;
    },
    setDiscounts: (state, action: PayloadAction<number>) => {
      state.totalDiscount = action.payload;
    },
    computeTotal: (state) => {
      state.total =
        state.totalLaborCost +
        state.totalPartCost +
        state.totalToolCost -
        state.totalDiscount;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(view.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state, { payload }) => {
      const {
        discountTotalAmount,
        laborTotalAmount,
        partsTotalAmount,
        toolTotalAmount,
        grandTotal,
      } = payload;

      state.status = "idle";
      state.data = payload;
      state.totalDiscount = number(discountTotalAmount);
      state.totalLaborCost = number(laborTotalAmount);
      state.totalPartCost = number(partsTotalAmount);
      state.totalToolCost = number(toolTotalAmount);
      state.total = number(grandTotal);
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(create.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(create.fulfilled, (state, { payload }) => {
      state.status = "created";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(update.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state, { payload }) => {
      state.status = "updated";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(updateStatus.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
      state.status = "status-updated";
      state.error = null;
    });
    builder.addCase(updateStatus.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(updateSchedule.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(updateSchedule.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(updateSchedule.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(approve.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(approve.fulfilled, (state, { payload }) => {
      state.status = "jo-created";
      state.error = null;
    });
    builder.addCase(approve.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const {
  reset,
  setData,
  setStatus,
  setLaborCost,
  setPartsCost,
  setToolsCost,
  setDiscounts,
  computeTotal,
} = slice.actions;

export default slice.reducer;

startAppListening({
  matcher: isAnyOf(setLaborCost, setPartsCost, setToolsCost, setDiscounts),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(computeTotal());
  },
});

import {
  get,
  map,
  toInteger,
  toString
} from "lodash/fp";
import request, { isAxiosError } from "../../../utils/axios";
import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { FieldMap } from "../../../types/field-map";
import dayjs from "dayjs";
import { ResponseStatus } from "../../../constants";

export interface ICustomerType {
  id: number;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
}

interface ICustomerTypePayload {
  id?: number;
  name: string;
  description: string;
  created_by?: number;
  updated_by?: number;
  created_at?: string; //YYYY-MM-DD HH:mm:ss
  updated_at?: string; //YYYY-MM-DD HH:mm:ss
}

const FIELD_MAPPING: FieldMap[] = [{
  key: "id",
  field: "id",
  transform: { response: (value: any) => toInteger(value) },
},
{
  key: "name",
  field: "name",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "description",
  field: "description",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "createdAt",
  field: "created_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "createdBy",
  field: "created_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
},
{
  key: "deletedAt",
  field: "deleted_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "deletedBy",
  field: "deleted_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
},
{
  key: "updatedAt",
  field: "updated_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "updatedBy",
  field: "updated_by",
  transform: { response: (value: any) => toString(value) },
}];

interface IError {
  message: string;
  status: number;
}

interface IParams {
  search?: string | null;
  per_page?: number;
  page?: number;
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden)
  .map(field => ({ column: field.key, path: field.path || field.key }));

export const parseData = (json: any): ICustomerType => {
  return FIELD_MAPPING.reduce((acc: ICustomerType, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as ICustomerType);
};

const parsePayload = (json: any): ICustomerTypePayload => {
  return FIELD_MAPPING.reduce((acc: ICustomerTypePayload, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {} as ICustomerTypePayload);
};

export const fetch = createAsyncThunk<{ customerTypes: ICustomerType[]; count: number }, IParams | undefined, { rejectValue: IError }>(
  "customer-types/fetch",
  async (params: IParams | undefined, { rejectWithValue }) => {
    try {
      const { data } = await request.get("api/customer-types", { params });
      return { customerTypes: map(parseData, data.data), count: data.total };

    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data?.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  });


export const create = createAsyncThunk<ICustomerType, ICustomerType, { rejectValue: IError }>(
  "customer-types/create",
  async (args: ICustomerType, { rejectWithValue }) => {
    try {
      const payload: ICustomerTypePayload = parsePayload(args);
      const { data } = await request.post("api/customer-types", { ...parsePayload(payload), created_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      if ([ResponseStatus.ERROR, ResponseStatus.FAILED].includes(data.data.status)) throw data.data.error;
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const update = createAsyncThunk<ICustomerType, ICustomerType, { rejectValue: IError }>(
  'customer-types/update',
  async (payload: ICustomerType, { rejectWithValue }) => {
    try {
      const { data } = await request.put('api/customer-types/update', { ...parsePayload(payload), updated_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const view = createAsyncThunk<ICustomerType, number, { rejectValue: IError }>(
  'customer-types/view',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`api/customer-types/${id}`);
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const remove = createAsyncThunk<ICustomerType, number, { rejectValue: IError }>(
  'customer-types/remove',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(`api/customer-types/${id}`);
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

type CustomerTypeState = {
  count: number;
  collection: ICustomerType[];
  status: "loading" | "idle" | "created" | "updated" | "deleted";
  error: string | null;
};

const initialState: CustomerTypeState = {
  count: 0,
  collection: [],
  status: "idle",
  error: null,
};
export const customerTypeSlice = createSlice({
  name: "customerType",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetch.fulfilled, (state, { payload }) => {
      state.count = payload.count;
      state.collection = payload.customerTypes;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetch.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(create.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(update.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(remove.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(remove.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(view.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = customerTypeSlice.actions;

export default customerTypeSlice.reducer;

import {
  first,
  get,
  getOr,
  map,
  toInteger,
  toString,
  transform,
} from "lodash/fp";
import request, { isAxiosError } from "../utils/axios";
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { FieldMap } from "../types/field-map";
import { ResponseStatus } from "../constants";
import { IRole, parseData as parseRoleData } from "./rolesSlice";

export interface IUser {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  passwordChangedAt: string;
  email: string;
  emailVerifiedAt: string;
  mobileNumber: string;
  isMasked: boolean;
  isLockOut: boolean;
  lockOutCount: number;
  lockOutDate: string;
  createdAt: string;
  createdBy: number;
  deletedAt: string;
  deletedBy: number;
  roleId?: number;
  roles: IRole[];
}
interface IUserPayload {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile_no: string;
  is_lock_out: number;
  lock_out_cnt: number;
  role_id: number;
  mfas: number[];
  loyalty_branch_id?: number;
  vendor_id?: number;
  updated_by?: number;
  created_at?: string; //YYYY-MM-DD HH:mm:ss
  updated_at?: string; //YYYY-MM-DD HH:mm:ss
  file?: File;
}

const FIELD_MAPPING: FieldMap[] = [{
  key: "id",
  field: "id",
  transform: { response: (value: any) => toInteger(value) },
}, {
  key: "username",
  field: "username",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "firstName",
  field: "first_name",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "lastName",
  field: "last_name",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "password",
  field: "password",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "passwordChangedAt",
  field: "password_changed_at",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "email",
  field: "email",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "emailVerifiedAt",
  field: "email_verified_at",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "mobileNumber",
  field: "mobile_no",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "isLockOut",
  field: "is_lock_out",
  transform: { response: (value: any) => !!toInteger(value) },
}, {
  key: "lockOutCount",
  field: "lock_out_cnt",
  transform: { response: (value: any) => toInteger(value) },
}, {
  key: "lockOutDate",
  field: "lock_out_date",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "createdAt",
  field: "created_at",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "createdBy",
  field: "created_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
}, {
  key: "updatedAt",
  field: "updated_at",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "updatedBy",
  field: "updated_by",
  transform: { response: (value: any) => toInteger(value) },
  hidden: true,
}, {
  key: "deletedAt",
  field: "deleted_at",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "deletedBy",
  field: "deleted_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
}, {
  key: "roleId",
  field: "role_id",
  transform: { response: (value: any) => toInteger(value) },
}, {
  key: "roles",
  field: "roles",
  transform: { response: (value: any) => map(parseRoleData, value) },
  hidden: true,
}];

export const parseData = (json: any): IUser => {
  return FIELD_MAPPING.reduce((acc: IUser, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IUser);
};

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key })
);

interface IError {
  message: string;
  status: number;
}

interface IParams {
  search?: string | null;
  per_page?: number;
  page?: number;
}

export const fetch = createAsyncThunk<
  { users: IUser[]; count: number },
  IParams | undefined,
  { rejectValue: IError }
>("users/fetch", async (params: IParams | undefined, { rejectWithValue }) => {
  try {
    const { data } = await request.get("api/users", { params });
    return { users: map(parseData, data.data), count: data.total };
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

const parsePayload = (json: any): IUserPayload => {
  return FIELD_MAPPING.reduce((acc: IUserPayload, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {} as IUserPayload);
};

export const create = createAsyncThunk<IUser, IUser, { rejectValue: IError }>(
  "users/create",
  async (args: IUser, { rejectWithValue }) => {
    try {
      const payload: IUserPayload = parsePayload(args);
      const { data } = await request.post("api/users", { ...payload, created_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      if ([ResponseStatus.ERROR, ResponseStatus.FAILED].includes(data.data.status)) throw data.data.error;
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const update = createAsyncThunk<
  IUser,
  IUserPayload,
  { rejectValue: IError }
>("users/update", async (payload: IUserPayload, { rejectWithValue }) => {
  try {
    const { data } = await request.put("api/users/update", {
      ...parsePayload(payload), updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    });
    if (
      [ResponseStatus.ERROR, ResponseStatus.FAILED].includes(data.data.status)
    ) {
      throw data.data.error;
    }
    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const view = createAsyncThunk<IUser, number, { rejectValue: IError }>(
  "users/view",
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`api/users/${id}`);
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const remove = createAsyncThunk<IUser, number, { rejectValue: IError }>(
  "users/remove",
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.post("api/destroy-users", {
        id,
        deleted_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      });
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const fetchServiceAdvisorUsers = createAsyncThunk<
  IUser[],
  IParams | undefined,
  { rejectValue: IError }
>("users/fetchServiceAdvisorUsers", async (params: IParams | undefined, { rejectWithValue }) => {
  try {
    const { data } = await request.get("api/users", { params: { ...params, role_id: 4 } });
    return map(parseData, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const fetchCustomerUsers = createAsyncThunk<
  IUser[],
  IParams | undefined,
  { rejectValue: IError }
>("users/fetchCustomerUsers", async (params: IParams | undefined, { rejectWithValue }) => {
  try {
    const { data } = await request.get("api/users", { params: { ...params, role_id: 6 } });
    return map(parseData, data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

type UsersState = {
  count: number;
  collection: IUser[];
  serviceAdvisors: IUser[];
  customers: IUser[];
  status: "loading" | "idle" | "created" | "updated" | "deleted";
  error: string | null;
};

const initialState: UsersState = {
  count: 0,
  collection: [],
  serviceAdvisors: [],
  customers: [],
  status: "idle",
  error: null,
};
export const userSlice = createSlice({
  name: "user",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetch.fulfilled, (state, { payload }) => {
      state.collection = payload.users;
      state.count = payload.count;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetch.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchServiceAdvisorUsers.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchServiceAdvisorUsers.fulfilled, (state, { payload }) => {
      state.serviceAdvisors = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchServiceAdvisorUsers.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchCustomerUsers.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchCustomerUsers.fulfilled, (state, { payload }) => {
      state.customers = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchCustomerUsers.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(create.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(create.fulfilled, (state) => {
      state.status = "created";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(update.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.status = "updated";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(remove.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.status = "deleted";
      state.error = null;
    });
    builder.addCase(remove.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(view.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = userSlice.actions;

export default userSlice.reducer;

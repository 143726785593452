import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { listenerMiddleware } from "./listenerMiddleware";

// middleware
const middleware: any[] = [listenerMiddleware.middleware];

// creating store
const createStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
    devTools: process.env.NODE_ENV !== "production",
  });

type ConfiguredStore = ReturnType<typeof createStore>;
type StoreGetState = ConfiguredStore["getState"];
export type RootState = ReturnType<StoreGetState>;
export type AppDispatch = ConfiguredStore["dispatch"];

export let store: ConfiguredStore;

export default () => {
  store = createStore();
  return store;
};

import {
  get,
  map,
  toInteger,
  toString
} from "lodash/fp";
import request, { isAxiosError } from "../utils/axios";
import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { FieldMap } from "../types/field-map";
import dayjs from "dayjs";
import { ResponseStatus } from "../constants";

export interface IRole {
  id: number;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
}

interface IRolePayload {
  id?: number;
  name: string;
  description: string;
  created_by?: number;
  updated_by?: number;
  created_at?: string; //YYYY-MM-DD HH:mm:ss
  updated_at?: string; //YYYY-MM-DD HH:mm:ss
}

const FIELD_MAPPING: FieldMap[] = [{
  key: "id",
  field: "id",
  transform: { response: (value: any) => toInteger(value) },
},
{
  key: "name",
  field: "name",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "description",
  field: "description",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "createdAt",
  field: "created_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "createdBy",
  field: "created_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
},
{
  key: "deletedAt",
  field: "deleted_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "deletedBy",
  field: "deleted_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
},
{
  key: "updatedAt",
  field: "updated_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "updatedBy",
  field: "updated_by",
  transform: { response: (value: any) => toString(value) },
}];

interface IError {
  message: string;
  status: number;
}

interface IParams {
  search?: string | null;
  per_page?: number;
  page?: number;
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden)
  .map(field => ({ column: field.key, path: field.path || field.key }));

export const parseData = (json: any): IRole => {
  return FIELD_MAPPING.reduce((acc: IRole, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IRole);
};

const parsePayload = (json: any): IRolePayload => {
  return FIELD_MAPPING.reduce((acc: IRolePayload, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {} as IRolePayload);
};

export const fetch = createAsyncThunk<
  { roles: IRole[]; count: number }, IParams | undefined, { rejectValue: IError }>(
    "roles/fetch",
    async (params: IParams | undefined, { rejectWithValue }) => {
      try {
        const { data } = await request.get("api/roles", { params });
        return { roles: map(parseData, data.data), count: data.total };
      } catch (error: any) {
        if (isAxiosError(error)) {
          const { response: { data, status } } = error as {
            response: { data: any; status: number }
          };
          return rejectWithValue({ message: data?.message, status });
        } else {
          return rejectWithValue({ message: error.message, status: 0 });
        }
      }
    });


export const fetchBranches = createAsyncThunk<
  { id: number; name: string }[],
  undefined,
  { rejectValue: IError }
>("roles/fetchBranches", async (args: undefined, { rejectWithValue }) => {
  try {
    const { data } = await request.post("api/list-loyalty-branches");
    return data.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const fetchVendors = createAsyncThunk<
  { id: number; name: string }[],
  undefined,
  { rejectValue: IError }
>("roles/fetchVendors", async (args: undefined, { rejectWithValue }) => {
  try {
    const { data } = await request.post("api/vendors");
    return data.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});
export const fetchMenus = createAsyncThunk<
  { id: number; name: string }[],
  number,
  { rejectValue: IError }
>("roles/fetchMenus", async (id: number, { rejectWithValue }) => {
  try {
    const { data } = await request.post("api/roles-menus", { id });
    return (function flatten(acc: any[], curr): any[] {
      if (curr == null) return acc;
      if (Array.isArray(curr)) return curr.reduce(flatten, acc);
      acc.push(curr);
      return flatten(acc, curr.children);
    })([], data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const fetchPermissions = createAsyncThunk<
  { id: number; name: string }[],
  number,
  { rejectValue: IError }
>("roles/fetchPermissions", async (id: number, { rejectWithValue }) => {
  try {
    const { data } = await request.post("api/roles-permissions", { id });
    return data.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});


export const create = createAsyncThunk<IRole, IRole, { rejectValue: IError }>(
  "roles/create",
  async (args: IRole, { rejectWithValue }) => {
    try {
      const payload: IRolePayload = parsePayload(args);
      const { data } = await request.post("api/roles", { ...parsePayload(payload), created_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      if ([ResponseStatus.ERROR, ResponseStatus.FAILED].includes(data.data.status)) throw data.data.error;
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const update = createAsyncThunk<IRole, IRole, { rejectValue: IError }>(
  'roles/update',
  async (payload: IRole, { rejectWithValue }) => {
    try {
      const { data } = await request.put('api/roles/update', { ...parsePayload(payload), updated_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const view = createAsyncThunk<IRole, number, { rejectValue: IError }>(
  'roles/view',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`api/roles/${id}`);
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const remove = createAsyncThunk<IRole, number, { rejectValue: IError }>(
  'roles/remove',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(`api/roles/${id}`);
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

type RoleState = {
  count: number;
  collection: IRole[];
  status: "loading" | "idle" | "created" | "updated" | "deleted";
  error: string | null;
};

const initialState: RoleState = {
  count: 0,
  collection: [],
  status: "idle",
  error: null,
};
export const roleSlice = createSlice({
  name: "role",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetch.fulfilled, (state, { payload }) => {
      state.collection = payload.roles;
      state.count = payload.count;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetch.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(create.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(update.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(remove.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(remove.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(view.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = roleSlice.actions;

export default roleSlice.reducer;

import { grey } from '@mui/material/colors';
import { PaletteColor, PaletteColorOptions, alpha, createTheme, darken, getContrastRatio, lighten } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface CommonColors {
    outline?: string;
    bg_primary?: string;
    bg_secondary?: string;
  }
  interface Palette {
    sample?: PaletteColor;
  }
  interface PaletteOptions {
    dark?: PaletteColorOptions | undefined;
  }
  interface PaletteColor {
    sample?: string;
  }

  interface SimplePaletteColorOptions {
    sample?: string;
  }
  interface TypographyVariants {
    small?: React.CSSProperties | undefined;
    formGroupLabel?: React.CSSProperties | undefined;
    dropzoneHeader?: React.CSSProperties | undefined;
  }

  interface TypographyVariantsOptions {
    small?: React.CSSProperties;
    formGroupLabel?: React.CSSProperties;
    dropzoneHeader?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    small: true;
    formGroupLabel: true;
    dropzoneHeader: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    dark: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    black: true;
    light: true;
  }
}

const generatePalleteColor = (color: string): PaletteColor => {
  return {
    light: lighten(color, 0.2),
    main: color,
    dark: darken(color, 0.2),
    contrastText: getContrastRatio(color, colors.white) > 4.5 ? colors.white : colors.black,
  }
};

const colors = {
  primary: '#064288',
  secondary: '#49454F',
  black: '#262D3B',
  white: '#FFFFFF',
  dark: '#444444',
  muted: '#707079',
  danger: '#B3261E',
  success: '#159400',
  bg_primary: '#F5F8FA',
  bg_secondary: '#F5F5F5',
  outline: '#1C1C1C'
};

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {}
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: colors.bg_secondary
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {}
    },
    MuiInputBase: {
      styleOverrides: {}
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100vh',
          textTransform: 'unset',
        }
      },
      variants: [
        {
          props: { variant: 'black' },
          style: {
            textTransform: 'initial',
            color: colors.black
          },
        }, {
          props: { variant: 'light' },
          style: {
            textTransform: 'initial',
            color: colors.black,
            backgroundColor: colors.white,
          },
        }
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.black
        },
      },
      variants: [
        {
          props: { variant: 'formGroupLabel' },
          style: {
            color: colors.primary,
            fontWeight: 600,
            fontSize: 16
          }
        },
        {
          props: { variant: 'dropzoneHeader' },
          style: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: 1.8,
            letterSpacing: 0.5,
          }
        }
      ],
      defaultProps: {
        variantMapping: {
          small: 'small',
          formGroupLabel: 'p',
          dropzoneHeader: 'h2',
        }
      }
    }
  },
  typography: {
    small: {
      fontSize: 12,
      lineHeight: 1.2
    }
  },
  palette: {
    common: {
      outline: colors.outline,
      white: colors.white,
      bg_primary: colors.bg_primary,
      bg_secondary: colors.bg_secondary
    },
    dark: generatePalleteColor(colors.dark),
    primary: generatePalleteColor(colors.primary),
    error: generatePalleteColor(colors.danger),
    success: generatePalleteColor(colors.success),
    secondary: generatePalleteColor(colors.secondary)
  }
});

export default theme;
import { toInteger, toString, get } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { IEstimate, ISchedule } from "../types";
import { parseData as parseEstimate } from "../transformers/estimate"
import dayjs from "dayjs";

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "estimateId",
    field: "estimate_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "jobOrderId",
    field: "job_order_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "customerId",
    field: "customer_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "status",
    field: { response: "latest_status", payload: "status" },
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "startDate",
    field: "start_date",
    transform: {
      response: (value: any) => toString(value),
      payload: (value: any) => dayjs(value).format("YYYY-MM-DD"),
    },
  },
  {
    key: "endDate",
    field: "end_date",
    transform: {
      response: (value: any) => toString(value),
      payload: (value: any) => dayjs(value).format("YYYY-MM-DD"),
    },
  },
  {
    key: "startTime",
    field: "start_time",
    transform: {
      response: (value: any) => toString(value),
      payload: (value: any) => {
        const d = dayjs(value)
        if (d.isValid()) return d.format("HH:mm:ss")
        else return dayjs(value, ["HH:mm:ss"], true).format("HH:mm:ss")
      },
    },
  },
  {
    key: "endTime",
    field: "end_time",
    transform: {
      response: (value: any) => toString(value),
      payload: (value: any) => {
        const d = dayjs(value)
        if (d.isValid()) return d.format("HH:mm:ss")
        else return dayjs(value, ["HH:mm:ss"], true).format("HH:mm:ss")
      }
    },
  },
  {
    key: "estimate",
    field: "estimate",
    transform: { response: (value: IEstimate) => parseEstimate(value) },
    exclude: true
  }
];

export const parseData = (json: any): ISchedule => {
  return FIELD_MAPPING.reduce((acc: ISchedule, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as ISchedule);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform, exclude } = curr;
    const value = get(key, json);
    if (exclude) return acc;
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
};

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key })
);

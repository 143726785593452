import {
  get,
  map,
  toInteger,
  toString
} from "lodash/fp";
import request, { isAxiosError } from "../utils/axios";
import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { FieldMap } from "../types/field-map";
import { ResponseStatus } from "../constants";

interface BaseAddress {
  id: number;
  name: string;
  shortName: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
}

export interface Country extends BaseAddress {
  locale: string;
  currency: string;
}

export interface Province extends BaseAddress {
  countryId: number;
}

export interface City extends BaseAddress {
  countryId: number;
  provinceId: number;
}

export interface Barangay extends BaseAddress {
  countryId: number;
  provinceId: number;
  cityId: number;
}

interface AddressMap {
  country: FieldMap[],
  province: FieldMap[],
  city: FieldMap[],
  barangay: FieldMap[]
};

const baseAddressFieldMap: FieldMap[] = [{
  key: "id",
  field: "id",
  transform: { response: (value: any) => toInteger(value) },
},
{
  key: "name",
  field: "name",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "shortName",
  field: "short_name",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "createdAt",
  field: "created_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "createdBy",
  field: "created_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
},
{
  key: "deletedAt",
  field: "deleted_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "deletedBy",
  field: "deleted_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
},
{
  key: "updatedBy",
  field: "updated_at",
  transform: { response: (value: any) => toString(value) },
},
{
  key: "updatedBy",
  field: "updated_by",
  transform: { response: (value: any) => toString(value) },
}]

const FIELD_MAPPING: AddressMap = {
  country: [
    ...baseAddressFieldMap,
    {
      key: "locale",
      field: "locale",
      transform: { response: (value: any) => toString(value) },
    }, {
      key: "currency",
      field: "currency",
      transform: { response: (value: any) => toString(value) },
    }],
  province: [
    ...baseAddressFieldMap,
    {
      key: "countryId",
      field: "country_id",
      transform: { response: (value: any) => toInteger(value) },
    }
  ],
  city: [
    ...baseAddressFieldMap,
    {
      key: "countryId",
      field: "country_id",
      transform: { response: (value: any) => toInteger(value) },
    },
    {
      key: "provinceId",
      field: "province_id",
      transform: { response: (value: any) => toInteger(value) },
    }
  ],
  barangay: [
    ...baseAddressFieldMap,
    {
      key: "countryId",
      field: "country_id",
      transform: { response: (value: any) => toInteger(value) },
    },
    {
      key: "provinceId",
      field: "province_id",
      transform: { response: (value: any) => toInteger(value) },
    },
    {
      key: "cityId",
      field: "city_id",
      transform: { response: (value: any) => toInteger(value) },
    }
  ]
};

type Address = Country | Province | City | Barangay;

export const parseData = (json: any, address: keyof AddressMap): Address => {
  return FIELD_MAPPING[address].reduce((acc: Address, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as Address);
};

export const countryKeys = FIELD_MAPPING['country']
  .filter(({ hidden }) => !hidden)
  .map(field => ({ column: field.key, path: field.path || field.key }));


export const provinceKeys = FIELD_MAPPING['province']
  .filter(({ hidden }) => !hidden)
  .map(field => ({ column: field.key, path: field.path || field.key }));

export const cityKeys = FIELD_MAPPING['city']
  .filter(({ hidden }) => !hidden)
  .map(field => ({ column: field.key, path: field.path || field.key }));

export const barangayKeys = FIELD_MAPPING['barangay']
  .filter(({ hidden }) => !hidden)
  .map(field => ({ column: field.key, path: field.path || field.key }));

interface IError {
  message: string;
  status: number;
}

interface IPayload {
  country_id?: number;
  province_id?: number;
  city_id?: number;
}

export const fetchCountries = createAsyncThunk<Address[], void, { rejectValue: IError }>("address/fetchCountries", async (_: void, { rejectWithValue }) => {
  try {
    const { data } = await request.get("api/countries");
    return map((json => parseData(json, 'country')), data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data?.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const fetchProvinces = createAsyncThunk<Address[], IPayload, { rejectValue: IError }>("address/fetchProvinces", async (params: IPayload, { rejectWithValue }) => {
  try {
    const { data } = await request.get("api/provinces", { params });
    return map((json => parseData(json, 'province')), data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data?.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const fetchCities = createAsyncThunk<Address[], IPayload, { rejectValue: IError }>("address/fetchCities", async (params: IPayload, { rejectWithValue }) => {
  try {
    const { data } = await request.get("api/cities", { params });
    return map((json => parseData(json, 'city')), data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data?.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const fetchBarangays = createAsyncThunk<Address[], IPayload, { rejectValue: IError }>("address/fetchBarangays", async (params: IPayload, { rejectWithValue }) => {
  try {
    const { data } = await request.get("api/barangays", { params });
    return map((json => parseData(json, 'barangay')), data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data?.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

type AddressState = {
  countries: Address[];
  provinces: Address[];
  cities: Address[];
  barangays: Address[];
  status: "loading" | "idle" | "created" | "updated" | "deleted";
  error: string | null;
};

const initialState: AddressState = {
  countries: [],
  provinces: [],
  cities: [],
  barangays: [],
  status: "idle",
  error: null,
};
export const addressSlice = createSlice({
  name: "address",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      state.countries = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchCountries.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchProvinces.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchProvinces.fulfilled, (state, { payload }) => {
      state.provinces = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchProvinces.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchCities.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchCities.fulfilled, (state, { payload }) => {
      state.cities = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchCities.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchBarangays.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchBarangays.fulfilled, (state, { payload }) => {
      state.barangays = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchBarangays.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = addressSlice.actions;

export default addressSlice.reducer;

import { get, map, toInteger, toString } from 'lodash/fp';
import request, { isAxiosError } from '../utils/axios';
import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import dayjs from 'dayjs';
import { FieldMap } from '../types/field-map';

export interface IMenu {
  id: number;
  label: string;
  isParent: boolean;
  parentMenuId: number;
  parentOrder: number;
  childOrder: number;
  description: string;
  alias: string;
}

const FIELD_MAPPING: FieldMap[] = [
  {
    key: 'id',
    field: 'id',
    transform: { response: (value: any) => toInteger(value) }
  },
  {
    key: 'label',
    field: 'label',
    transform: { response: (value: any) => toString(value) }
  },
  {
    key: 'isParent',
    field: 'is_parent',
    transform: { response: (value: any) => !!toInteger(value) }
  },
  {
    key: 'parentMenuId',
    field: 'parent_menu_id',
    transform: { response: (value: any) => toInteger(value), }
  },
  {
    key: 'parentOrder',
    field: 'parent_order',
    transform: { response: (value: any) => toInteger(value) }
  },
  {
    key: 'childOrder',
    field: 'child_order',
    transform: { response: (value: any) => toInteger(value) }
  },
  {
    key: 'alias',
    field: 'alias',
    transform: { response: (value: any) => toString(value) }
  },
  {
    key: 'description',
    field: 'description',
    transform: { response: (value: any) => toString(value) }
  }
];

export const parseData = (json: any): IMenu => {
  return FIELD_MAPPING.reduce((acc: IMenu, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === 'string' ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted }
  }, {} as IMenu);
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(field => ({ column: field.key, path: field.path || field.key }));

interface IError {
  message: string;
  status: number;
}

interface IFetchMenuPayload {
  page: number;
  per_page: number;
  search?: string | null;
}

export const fetch = createAsyncThunk<{ menus: IMenu[], count: number }, IFetchMenuPayload | undefined, { rejectValue: IError }>(
  'menus/fetch',
  async (args: IFetchMenuPayload | undefined, { rejectWithValue }) => {
    try {
      const { data } = await request.post('api/list-menus', args);
      return { menus: map(parseData, data.data), count: data.total || 0 };
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

interface IStoreMenuPayload {
  id?: number;
  label: string;
  is_parent: number;
  parent_menu_id: number | null;
  child_order: number;
  alias: string;
  description: string;
}

const parsePayload = (json: any): IStoreMenuPayload => {
  return FIELD_MAPPING.reduce((acc: IStoreMenuPayload, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return { ...acc, ...(typeof value != 'undefined' && { [typeof field === 'string' ? field : field.payload]: transform?.payload ? transform.payload(value) : value }) };
  }, {} as IStoreMenuPayload);
}

export const create = createAsyncThunk<IMenu, IMenu, { rejectValue: IError }>(
  'menus/create',
  async (payload: IMenu, { rejectWithValue }) => {
    try {
      const { data } = await request.post('api/store-menus', { ...parsePayload(payload), created_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const update = createAsyncThunk<IMenu, IMenu, { rejectValue: IError }>(
  'menus/update',
  async (payload: IMenu, { rejectWithValue }) => {
    try {
      const { data } = await request.post('api/update-menus', { ...parsePayload(payload), updated_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const view = createAsyncThunk<IMenu, number, { rejectValue: IError }>(
  'menus/view',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.post('api/show-menus', { id });
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const remove = createAsyncThunk<IMenu, number, { rejectValue: IError }>(
  'menus/remove',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.post('api/destroy-menus', { id, deleted_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

type CongfigState = {
  count: number,
  collection: IMenu[],
  status: 'loading' | 'idle';
  error: string | null;
}

const initialState: CongfigState = {
  count: 0,
  collection: [],
  status: 'idle',
  error: null
};
export const configSlice = createSlice({
  name: 'menu',
  initialState: { ...initialState },
  reducers: {
    reset: (state, action: PayloadAction<any>) => {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(fetch.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetch.fulfilled, (state, { payload }) => {
      state.collection = payload.menus;
      state.count = payload.count;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetch.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(create.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(create.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(update.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(remove.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(remove.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(view.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  }
});

export const { reset } = configSlice.actions;

export default configSlice.reducer;
import { SvgIconTypeMap } from "@mui/material";
import {
  CalculateOutlined,
  ManageAccountsOutlined,
  DirectionsCarOutlined,
  ConstructionOutlined,
  WorkOutlineOutlined,
  TrendingUpOutlined,
  CalendarTodayOutlined,
  GroupsOutlined,
  PaymentOutlined,
  InventoryOutlined,
  SummarizeOutlined,
  BadgeOutlined,
  ConfirmationNumberOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export interface MenuMap {
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
  pathname?: string;
}

const MENU_MAPPING: { [key: string]: MenuMap } = {
  main_dashboard: { Icon: TrendingUpOutlined, pathname: "/" },
  
  main_calendars: { Icon: CalendarTodayOutlined },
  calendar_views: { pathname: "/calendar" },
  calendar_schedules: { pathname: "/calendar/schedules" },

  main_estimates: { Icon: CalculateOutlined },
  estimates_all: { pathname: "/estimates" },
  estimates_service_requests: { pathname: "/estimates/service-requests" },
  
  main_job_orders: { Icon: WorkOutlineOutlined, pathname: "/job-orders" },
  
  main_customers: { Icon: GroupsOutlined },
  customers_all: { pathname: "/customers" },
  customers_types: { pathname: "/customers/types" },
  
  main_payments: { Icon: PaymentOutlined },
  payments_all: { pathname: "/payments" },
  payments_terms: { pathname: "/payments/terms" },
  payments_types: { pathname: "/payments/types" },
  payments_invoices: { pathname: "/payments/invoices" },
  
  main_inventory: { Icon: InventoryOutlined, pathname: "/inventory" },

  main_vehicles: { Icon: DirectionsCarOutlined },
  vehicles_all: { pathname: "/vehicles" },
  vehicles_types: { pathname: "/vehicles/types" },
  vehicles_brands: { pathname: "/vehicles/brands" },
  vehicles_fuel_types: { pathname: "/vehicles/fuel-types" },
  
  main_reports: { Icon: SummarizeOutlined, pathname: "/reports" },
  
  main_employees: { Icon: BadgeOutlined },

  main_user_management: { Icon: ManageAccountsOutlined },
  users_all: { pathname: "/users" },
  users_roles: { pathname: "/users/roles" },
  users_permissions: { pathname: "/users/permissions" },
  
  main_shop_management: { Icon: StorefrontOutlined },
  shops_all: { pathname: "/shops" },
  
  main_service_management: { Icon: ConstructionOutlined },
  services_requirements: { pathname: "/services/requirements" },
  services_location_types: { pathname: "/services/location-types" },
  services_standard: { pathname: "/services/standard-services" },
  services_job_types: { pathname: "/services/job-types" },
  services_labors: { pathname: "/services/labors" },
  services_labors_all: { pathname: "/services/labors" },
  services_labors_types: { pathname: "/services/labors/types" },
  services_parts: { pathname: "/services/parts" },
  services_parts_all: { pathname: "/services/parts" },
  services_parts_categories: { pathname: "/services/parts/categories" },
  services_parts_subcategories: { pathname: "/services/parts/subcategories" },
  services_tools: { pathname: "/services/tools" },
  services_tools_all: { pathname: "/services/tools" },
  services_tools_types: { pathname: "/services/tools/types" },
  services_checklists: { pathname: "/services/checklists" },
  
  main_discount_management: { Icon: ConfirmationNumberOutlined },
  discounts_all: { pathname: "/discounts" },
};

export default MENU_MAPPING;

import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";


type LoadingContextValue = React.Dispatch<React.SetStateAction<boolean>>;

const LoadingContext = React.createContext<LoadingContextValue | undefined>(undefined);

const LoadingProvider: React.FC<any> = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  return (
    <LoadingContext.Provider value={setIsLoading}>
      {children}
      <Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={isLoading}>
        <CircularProgress size={100} color="inherit" />
      </Backdrop>
    </LoadingContext.Provider>
  )
}

export const useLoadingContext = () => {
  const loadingContext = React.useContext(LoadingContext);
  if (loadingContext === undefined) {
    throw new Error('useLoadingContext must be inside a LoadingProvider');
  }
  return loadingContext;
};
export default LoadingProvider;
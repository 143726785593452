import React, { PropsWithChildren } from "react";
import { ThemeProvider } from "@mui/system";
import Layout from "./Layout";
import theme from "../theme";
import { CssBaseline } from "@mui/material";
import ToastProvider from "./ToastContext";
import LoadingProvider from "./LoadingContext";
import { useAuth } from "./AuthProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Page = ({ children, ...props }: PropsWithChildren) => {
  const { isLoading, token } = useAuth();
  return !isLoading && token ? (
    <Layout {...props}>{children}</Layout>
  ) : (
    children
  );
};

const PageWrapper = ({ props, element }: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LoadingProvider>
          <ToastProvider>
            <Page {...props}>{element}</Page>
          </ToastProvider>
        </LoadingProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default PageWrapper;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { isAxiosError } from "../../utils/axios";
import { IError } from "../../types/error";
import { IVehicle } from "../../types";
import { ResponseStatus } from "../../constants";
import { parseData, parsePayload } from "../../transformers/vehicle";
import dayjs from "dayjs";

export const view = createAsyncThunk<
  IVehicle,
  number,
  { rejectValue: IError }
>("vehicle/form/view", async (id, { rejectWithValue }) => {
  try {
    const { data } = await request.get(`api/vehicles/${id}`);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const create = createAsyncThunk<
  IVehicle,
  IVehicle,
  { rejectValue: IError }
>("vehicle/form/create", async (args, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    const payload: IVehicle = parsePayload(args);
    Object.entries(payload).forEach(([key, value]) => formData.append(key, value as any));
    formData.append("created_at", dayjs().format("YYYY-MM-DD HH:mm:ss"));
    const { data } = await request.post("api/vehicles", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (
      [ResponseStatus.ERROR, ResponseStatus.FAILED].includes(data.data.status)
    ) {
      throw data.data.error;
    }

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const update = createAsyncThunk<
  IVehicle,
  IVehicle,
  { rejectValue: IError }
>("vehicle/form/update", async (args, { rejectWithValue }) => {
  try {
    const payload = parsePayload(args);
    const { data } = await request.put("api/vehicles/update", payload);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const { response: { data, status } } = error as {
        response: { data: any; status: number }
      };
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

type InitialState = {
  data: IVehicle | null;
  status: "loading" | "idle" | "created" | "updated";
  error: string | null;
};

const initialState: InitialState = {
  data: null,
  status: "idle",
  error: null,
};

export const slice = createSlice({
  name: "vehicle/form",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(view.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.data = payload;
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(create.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(create.fulfilled, (state, { payload }) => {
      state.status = "created";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(update.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state, { payload }) => {
      state.status = "updated";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

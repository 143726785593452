import { combineReducers } from 'redux';

import customers from './slice';
import vehicles from './vehicles';
import types from './types';

export default combineReducers({
  $: customers,
  vehicles,
  types
});

import { combineReducers } from 'redux';

import standardServiceSlice from './standard-service';
import jobTypeSlice from './job-type';
import locationTypeSlice from './location-type';
import laborSlice from './labor';
import laborTypeSlice from './labor-type';
import toolSlice from './tool';
import toolTypeSlice from './tool-type';
import partSlice from './part';
import partCategorySlice from './part-category';
import partSubcategorySlice from './part-subcategory';

export default combineReducers({
  standardService: standardServiceSlice,
  jobType: jobTypeSlice,
  locationType: locationTypeSlice,
  labor: laborSlice,
  laborType: laborTypeSlice,
  tool: toolSlice,
  toolType: toolTypeSlice,
  part: partSlice,
  partCategory: partCategorySlice,
  partSubcategory: partSubcategorySlice,
});

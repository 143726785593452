import axios, { AxiosError, AxiosResponse } from 'axios';
import { jwtDecode } from "jwt-decode";

const request = axios.create({
  baseURL: process.env.GATSBY_API_BASEURL,
  headers: {
    common: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  }
});

const responseInterceptors: Map<string, number> = new Map();

// Append created_by on POST request
request.interceptors.request.use(config => {
  const { headers, method, data } = config;
  if (headers.Authorization) {
    const token: string = (headers.Authorization as string).split(' ').pop()!;
    const userId = jwtDecode(token).sub;
    if (userId && method == 'post') {
      if (data) {
        if (data.constructor.name == 'FormData') {
          data.append('created_by', userId);
        } else if (data.constructor.name == 'Object') {
          data['created_by'] = userId;
        }
      }
    }
  }
  return config;
})

export const setToken = (token: string | null) => {
  if (token) request.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setBaseUrl = (url: string) => {
  request.defaults.baseURL = url;
}

export const addResponseInterceptor = (key: string, onFulfilled: ((value: AxiosResponse<any, any>) => any) | null | undefined, onRejected?: ((error: any) => any) | null | undefined) => {
  let instance = responseInterceptors.get(key);
  if (typeof instance !== 'undefined') request.interceptors.response.eject(instance);
  instance = request.interceptors.response.use(onFulfilled, onRejected);
  responseInterceptors.set(key, instance);
}

export const isAxiosError = (err: Error | AxiosError) => {
  return axios.isAxiosError(err);
}

export const mock = (success: boolean, response: any = {}, timeout: number = 1000) => {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve({ data: response });
      } else {
        reject({ message: 'Error' });
      }
    }, timeout);
  });
}

export default request;

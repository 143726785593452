import { Alert, Snackbar } from "@mui/material";
import React from "react";


type Toast = {
  open: boolean;
  message: string | null;
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
}

type ToastContextValue = React.Dispatch<React.SetStateAction<Toast | null>>;

const ToastContext = React.createContext<ToastContextValue | undefined>(undefined);

const ToastProvider: React.FC<any> = ({ children }) => {
  const [toast, setToast] = React.useState<Toast | null>(null);
  return (
    <ToastContext.Provider value={setToast}>
      {children}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={toast?.open} autoHideDuration={5000} onClose={() => setToast(null)}>
        <Alert onClose={() => setToast(null)} severity={toast?.severity} sx={{ width: '100%' }}>{toast?.message}</Alert>
      </Snackbar>
    </ToastContext.Provider>
  )
}

export const useToastContext = () => {
  const toastContext = React.useContext(ToastContext);
  if (toastContext === undefined) {
    throw new Error('useToastContext must be inside a ToastProvider');
  }
  return toastContext;
};
export default ToastProvider;
import React from 'react';
import { Provider } from 'react-redux';
import createStore from '../store';
import { StyledEngineProvider } from '@mui/material/styles';
import AuthProvider from './AuthProvider';

const RootWrapper = ({ element }: any) => {
  const store = createStore();
  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <AuthProvider>
          <Provider store={store}>{element}</Provider>
        </AuthProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export default RootWrapper;
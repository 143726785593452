enum EstimateStatus {
  ESTIMATING = "Estimating",
  FOR_CLIENT_APPROVAL = "For Client Approval",
  CLIENT_APPROVED = "Client Approved",
  APPROVED = "Approved",
  DECLINED = "Declined",
  CANCELLED = "Cancelled",
}

export const STATUS_TEXT_MAPPING: { [key: string]: string } = {
  "Pending": "Estimating",
}

export const STATUS_COLOR_MAPPING: { [key: string]: string } = {
  [EstimateStatus.ESTIMATING]: "#064288",
  [EstimateStatus.FOR_CLIENT_APPROVAL]: "#BF9500",
  [EstimateStatus.CLIENT_APPROVED]: "#BF9500",
  [EstimateStatus.APPROVED]: "#007246",
  [EstimateStatus.DECLINED]: "#BF0900",
  [EstimateStatus.CANCELLED]: "#959595",
}

export default EstimateStatus;

import {
  get,
  map,
  toInteger,
  toString
} from "lodash/fp";
import request, { isAxiosError } from "../../../utils/axios";
import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { IVehicle } from "../../../types";
import { parseData } from "../../../transformers/vehicle";

interface IError {
  message: string;
  status: number;
}

interface IParams {
  search?: string | null;
  per_page?: number;
  page?: number;
  customer_id?: number | string;
}

export const fetch = createAsyncThunk<
  { vehicles: IVehicle[]; count: number }, IParams | undefined, { rejectValue: IError }>(
    "customer-vehicle/fetch",
    async (params: IParams | undefined, { rejectWithValue }) => {
      try {
        const { data } = await request.get("api/customer-vehicles", { params });
        return { vehicles: map(({ vehicle }) => parseData(vehicle), data.data), count: data.total };
      } catch (error: any) {
        if (isAxiosError(error)) {
          const { response: { data, status } } = error as {
            response: { data: any; status: number }
          };
          return rejectWithValue({ message: data?.message, status });
        } else {
          return rejectWithValue({ message: error.message, status: 0 });
        }
      }
    });


type VehicleState = {
  count: number;
  collection: IVehicle[];
  status: "loading" | "idle" | "created" | "updated" | "deleted";
  error: string | null;
};

const initialState: VehicleState = {
  count: 0,
  collection: [],
  status: "idle",
  error: null,
};
export const vehicleSlice = createSlice({
  name: "vehicle",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetch.fulfilled, (state, { payload }) => {
      state.collection = payload.vehicles;
      state.count = payload.vehicles.length;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetch.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = vehicleSlice.actions;

export default vehicleSlice.reducer;

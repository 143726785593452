import { toInteger, toString, toNumber } from 'lodash/fp'; 

export const currencyToNumber = (currency: any): number => {
  try {
    return Number(toString(currency).replace(/[^0-9.-]+/g, ""))
  } catch (e) {
    return currency;
  }
}

export const emptyStringToNull = (value: any, originalValue: any) => {
  if (typeof originalValue === "string" && originalValue === "") return null;
  
  return value;
}

export const formatCurrency = (value: string) => {
  const newValue = toString(value);
  if (isNaN(toNumber(newValue))) return '';

  return (toNumber(newValue)).toLocaleString("en-US", { minimumFractionDigits: 2 });
}

export const number = (value: any) => Number(toString(value).replace(/[^0-9.-]+/g, ""))

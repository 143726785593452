import React, { ComponentType } from "react";
import request, {
  addResponseInterceptor,
  isAxiosError,
  setToken as setBearerToken,
} from "../utils/axios";
import { find, first, get, getOr, map, toInteger, toString } from "lodash/fp";
import { PageProps, navigate } from "gatsby";
import { jwtDecode } from "jwt-decode";
import { AxiosError } from "axios";
import { useToastContext } from "./ToastContext";
import { useLocation } from "@reach/router";

const faker = async (type: "login" | "user"): Promise<any> => {
  const dummy = {
    login: await fetch("/dummy.json")
      .then((res) => res.json())
      .then((res) => ({
        data: {
          access_token: res.access_token,
          authenticated_user: res.user,
        },
      })),
    user: await fetch("/dummy.json")
      .then((res) => res.json())
      .then((res) => [res.user, res.avatar]),
  };
  return dummy[type];
};

interface AuthUser {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isLockOut: string;
  mobileNo: string;
  tempOtp: string;
  mfas: { name: string; isDefault: boolean }[];
  roles: { id: number; name: string }[];
}

interface ProfilePicture {
  id: number;
  name: string;
  documentUrl: string;
}

interface Menu {
  id: number;
  label: string;
  isParent: boolean;
  parentMenuId: number;
  parentOrder: number;
  childOrder: number;
  alias: string;
  children: Menu[];
}

interface Permission {
  id: number;
  name: string;
}

const parseUserPermssion = (json: any): Permission => {
  return {
    id: toInteger(get("id", json)),
    name: toString(get("name", json)),
  };
};

const parseProfilePicture = (json: any): ProfilePicture => {
  return {
    id: toInteger(get("id", json)),
    name: toString(get("name", json)),
    documentUrl: toString(get("document_url", json)),
  };
};

const parseUserMenu = (json: any): Menu => {
  return {
    id: toInteger(get("id", json)),
    label: toString(get("label", json)),
    isParent: !!toInteger(get("is_parent", json)),
    parentMenuId: toInteger(get("parent_menu_id", json)),
    parentOrder: toInteger(get("parent_order", json)),
    childOrder: toInteger(get("child_order", json)),
    alias: toString(get("alias", json)),
    children: map((menu) => parseUserMenu(menu), getOr([], "children", json)),
  };
};

const parseAuthUser = (json: any): AuthUser => {
  return {
    id: toInteger(get("id", json)),
    username: toString(get("username", json)),
    firstName: toString(get("first_name", json)),
    lastName: toString(get("last_name", json)),
    email: toString(get("email", json)),
    isLockOut: toString(get("is_lock_out", json)),
    mobileNo: toString(get("mobile_no", json)),
    tempOtp: toString(get("temp_otp", json)),
    mfas: map(
      (mfa) => ({
        name: toString(get("name", mfa)),
        isDefault: !!toInteger(get("is_default", mfa)),
      }),
      getOr([], "mfas", json)
    ),
    roles: map(
      (role) => ({
        id: toInteger(get("id", role)),
        name: toString(get("name", role)),
      }),
      getOr([], "roles", json)
    ),
  };
};

interface Auth {
  isLoading: boolean;
  user: AuthUser | null;
  profilePicture: ProfilePicture | null;
  menu: Menu[];
  permissions: Permission[];
  token: string | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

let token: string | (() => string | null) | null;
if (typeof window !== "undefined") {
  token = window.sessionStorage.getItem("token");
}

const AuthContext = React.createContext<Auth | undefined>(undefined);

const AuthProvider: React.FC<any> = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [user, setUser] = React.useState<AuthUser | null>(null);
  const [profilePicture, setProfilePicture] =
    React.useState<ProfilePicture | null>(null);
  const [menu, setMenu] = React.useState<Menu[]>([]);
  const [permissions, setPermissions] = React.useState<Permission[]>([]);
  const [sessionToken, setSessionToken] = React.useState<string | null>(token);

  const login = async (username: string, password: string) => {
    setIsLoading(true);
    try {
      const auth =
        process.env.NODE_ENV == "development" && username === "jrocket"
          ? await faker("login")
          : await request
              .post("/oauth/token", {
                grant_type: "client_credentials",
                client_id: toString(process.env.GATSBY_CLIENT_ID),
                client_secret: toString(process.env.GATSBY_CLIENT_SECRET),
              })
              .then(({ data: client }) =>
                request.post(
                  "/api/login",
                  { username, password },
                  {
                    headers: { Authorization: `Bearer ${client.access_token}` },
                  }
                )
              );
      const { data } = auth;
      window.sessionStorage.setItem("token", data.access_token);
      setBearerToken(data.access_token);
      setSessionToken(data.access_token);
      setUser(parseAuthUser(data.authenticated_user));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error;
        throw { message: data.message, status };
      } else {
        throw { message: error.message, status: 0 };
      }
    } finally {
      setIsLoading(false);
    }
  };

  const clearSession = () => {
    setIsLoading(false);
    setBearerToken(null);
    setSessionToken(null);
    setUser(null);
    window.sessionStorage.removeItem("token");
    navigate("/login");
  };

  const logout = async () => {
    try {
      await request.post("/api/logout");
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error;
        throw { message: data.message, status };
      } else {
        throw { message: error.message, status: 0 };
      }
    } finally {
      clearSession();
    }
    return Promise.reject();
  };

  const getAuthUser = async (id: number) => {
    setIsLoading(true);
    try {
      const [authUser, menus, permissions] =
        process.env.NODE_ENV == "development" && id === 99999
          ? await faker("user")
          : await Promise.all([
              request
                .get<{ data: AuthUser }>(`/api/users/${id}`)
                .then(({ data }) => data.data),
            ]).then(([authUser]) => {
              const role = first(authUser.roles);
              if (role) {
                return Promise.all([
                  request
                    .post("/api/roles-menus", { id: role.id })
                    .then(({ data }) => data.data),
                  request
                    .post("/api/roles-permissions", { id: role.id })
                    .then(({ data }) => data.data),
                ]).then(([menus, permissions]) => [
                  authUser,
                  menus,
                  permissions,
                ]);
              } else {
                return [authUser, [], []];
              }
            });
      setUser(parseAuthUser(authUser));
      setProfilePicture(parseProfilePicture(profilePicture));
      setMenu(map(parseUserMenu, menus));
      setPermissions(map(parseUserPermssion, permissions));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const {
          response: { data, status },
        } = error;
        throw { message: data.message, status };
      } else {
        throw { message: error.message, status: 0 };
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    let hasAuthUser = false;
    setBearerToken(sessionToken);
    (async (token) => {
      if (!!token) {
        if (!hasAuthUser) {
          const { sub } = jwtDecode(token);
          try {
            await getAuthUser(toInteger(sub));
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        setIsLoading(false);
      }
    })(sessionToken);
    return () => {
      hasAuthUser = true;
    };
  }, [sessionToken]);

  React.useEffect(() => {
    addResponseInterceptor("UNAUTHORIZED", undefined, (err: AxiosError) => {
      const error = err.response;
      const ignore = ["/api/login", "/oauth/token", "/api/logout"];
      if (err.config?.url && !ignore.includes(err.config?.url)) {
        if (error?.status && [401, 403].includes(error?.status))
          logout().then(console.log).catch(console.error);
      }
      return Promise.reject(err);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        token: sessionToken,
        user,
        profilePicture,
        menu,
        permissions,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = (): Auth => {
  const authContext = React.useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error("useAuthContext must be inside a AuthProvider");
  }
  return authContext;
};

export const withAuthenticationRequired = <P extends PageProps>(
  Component: ComponentType<P>,
  _?: any
): React.FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { isLoading, token, permissions, user } = useAuth();
    const setToast = useToastContext();
    const location: any = useLocation();
    const [isAuthorized, setIsAuthorized] = React.useState<boolean>(false);
    const [isPermitted, setIsPermitted] = React.useState<boolean>(false);
    React.useEffect(() => {
      if (process.env.NODE_ENV == "development") {
        console.info("PAGE_PATH:", props.path);
      }
      if (!isLoading) {
        if (!token) {
          navigate("/login");
        } else {
          setIsAuthorized(true);
          fetch("/permissions.json")
            .then((res) => res.json())
            .then((paths) => {
              const path = find(["path", props.path], paths);
              if (
                path &&
                !map("name", permissions).includes(path.permission) &&
                !(process.env.NODE_ENV == "development" && user?.id === 99999)
              ) {
                setToast({
                  open: true,
                  message: `You do not have permission to access page ${location.pathname}.`,
                  severity: "error",
                });
                const referrer = location.state?.referrer
                  ? location.state?.referrer
                  : "/";
                navigate(referrer);
              } else {
                setIsPermitted(true);
              }
            });
        }
      }
    }, [token, isLoading]);

    return isAuthorized && isPermitted ? (
      <Component {...props} />
    ) : (
      <React.Fragment></React.Fragment>
    );
  };
};

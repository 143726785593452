import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request, { isAxiosError } from "../../../utils/axios";
import { IError } from "../../../types/error";
import { IPart } from "../../../types";
import { ResponseStatus } from "../../../constants";
import { parseData, parsePayload } from "../../../transformers/part";

export const view = createAsyncThunk<
  IPart,
  number,
  { rejectValue: IError }
>("part/form/view", async (id, { rejectWithValue }) => {
  try {
    const { data } = await request.get(`api/parts/${id}`);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error;
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const create = createAsyncThunk<
  IPart,
  IPart,
  { rejectValue: IError }
>("part/form/create", async (args, { rejectWithValue }) => {
  try {
    const payload = parsePayload(args);
    const { data } = await request.post("api/parts", payload);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error;
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

export const update = createAsyncThunk<
  IPart,
  IPart,
  { rejectValue: IError }
>("part/form/update", async (args, { rejectWithValue }) => {
  try {
    const payload = parsePayload(args);
    const { data } = await request.put("api/parts/update", payload);

    if (data.data.status === ResponseStatus.ERROR || data.data.errors)
      throw data.data;

    return parseData(data.data);
  } catch (error: any) {
    if (isAxiosError(error)) {
      const {
        response: { data, status },
      } = error;
      return rejectWithValue({ message: data.message, status });
    } else {
      return rejectWithValue({ message: error.message, status: 0 });
    }
  }
});

type InitialState = {
  data: IPart | null;
  status: "loading" | "idle" | "created" | "updated";
  error: string | null;
};

const initialState: InitialState = {
  data: null,
  status: "idle",
  error: null,
};

export const slice = createSlice({
  name: "part/form",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(view.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.data = payload;
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    
    builder.addCase(create.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(create.fulfilled, (state, { payload }) => {
      state.status = "created";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(update.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state, { payload }) => {
      state.status = "updated";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;
